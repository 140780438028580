import { path as rPath } from "ramda";

export type Invalidation = [
  ReadonlyArray<string | number>,
  string,
  string,
  any,
  any
];
type MessageFunc = (args: {
  type: string;
  rule: string;
  setting: any;
  value: any;
}) => string;

export const invalidationMessages: {
  [type: string]: { [rule: string]: MessageFunc };
} = {
  String: {
    required: () => "Required",
    blank: () => "Required",
    options: () => "Select an option",
    "format-email": () => "Enter a valid email",
    "format-gregorian-date": () => "Select a valid date",
    "format-time24h": () => "Select a valid time",
    maxLength: ({ setting }: { setting: number }) =>
      `Enter at most ${setting} characters`,
  },
  Number: {
    required: () => "Required",
    minimum: ({ setting }: { setting: number }) =>
      `Must be greater than ${setting}`,
    maximum: ({ setting }: { setting: number }) =>
      `Must be less than ${setting}`,
    precision: ({ setting }: { setting: number }) =>
      setting > 0 ? `Enter at most ${setting} decimals` : `Enter an integer`,
    type: ({ value }: { value: string }) => {
      if (value === "String") {
        return "Enter a positive integer";
      }

      return "type";
    },
  },
  Array: {
    minItems: (args: { setting: number }) => `Select at least ${args.setting}`,
  },
  Object: {
    additionalProperties: () => `Required`,
    required: () => `Required`,
  },
  AppointmentReport: {
    alreadyExists: () => "Another report for this appointment already exists",
  },
  User: {
    invalidAdminAndAudiologist: () => `Cannot be admin and audiologist`,
  },
  Aggregate: {
    notFound: () => `Select an option`,
  },
  // this is an extemely hacky way of showing an error
  // but without it we have no way of showing an invalidation for when the address is invalid
  // i.e. not acceptec by PostNord
  Audiologist: {
    invalidAddress: () => {
      alert("The address for the selected audiologist is not valid");
      return ``;
    },
  },
} as const;

export const invalidationToMessage = (invalidation: Invalidation): string => {
  const [, type, rule, setting, value] = invalidation;
  const messageFunc = rPath([type, rule], invalidationMessages) as any;

  if (messageFunc !== undefined) {
    return messageFunc({ type, rule, setting, value });
  } else {
    process.env.NODE_ENV === "development" &&
      console.warn(
        `missing message for invalidation: ${[type, rule, setting, value].join(
          ", "
        )}`
      );
    return rule;
  }
};
