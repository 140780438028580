import { GLOBALS } from "./globals";
import { createFieldTextComponents } from "./setup-factories";

export const { FieldText } = createFieldTextComponents({
  field: {
    label: {
      placement: "top",
      align: "start",
      padding: "8px",
      font: {
        family: "'Roboto', sans-serif",
        size: "14px",
        lineHeight: "110%",
        color: GLOBALS.color.onLight[500],
      },
    },

    error: {
      placement: "bottom",
      align: "start",
      padding: "4px",
      font: {
        family: "'Roboto', sans-serif",
        size: "12px",
        color: GLOBALS.color.errorRed[500],
      },
    },
  },

  input: {
    input: {
      border: {
        width: "1px",
        radius: {
          bottomLeft: "6px",
          bottomRight: "6px",
          topLeft: "6px",
          topRight: "6px",
        },
        style: "solid",
        color: GLOBALS.color.grey[200],
      },
      padding: {
        top: "13px",
        right: "14px",
        bottom: "13px",
        left: "14px",
      },
      font: {
        family: "'Roboto', sans-serif",
        size: "16px",
        lineHeight: "19px",
        weight: 400,
        color: GLOBALS.color.onLight[500],
      },
      placeholderColor: GLOBALS.color.grey[400],
      background: { color: "white" },
    },

    $when: {
      readonly: {
        input: {
          background: { color: GLOBALS.color.grey[100] },
          border: { color: GLOBALS.color.grey[200] },
        },
      },
      disabled: {
        input: {
          background: { color: GLOBALS.color.grey[100] },
          border: { color: GLOBALS.color.grey[200] },
          font: { color: GLOBALS.color.grey[400] },
        },
      },
      focus: {
        input: {
          border: { color: GLOBALS.color.grey[500] },
        },
      },
      invalid: {
        input: {
          border: { color: GLOBALS.color.errorRed[500] },
        },
      },
    },
  },
});
