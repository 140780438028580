import { createBoxComponents } from "./setup-factories";

export const { BoxRow, BoxRowCenter, BoxRowBaseline, BoxRowBottom } =
  createBoxComponents(
    {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    {
      Row: {},
      RowCenter: { alignItems: "center" },
      RowBaseline: { alignItems: "baseline" },
      RowBottom: { alignItems: "flex-end" },
    }
  );
