/*
 * This is copied from shared-components:
 * https://gitlab.com/webnuts/npm/browser/shared-components/-/blob/4dded53d58a40cfc3dd007e946a88b69a62f3197/src/projects/common/Spinner.tsx#L403-446
 * We copy it instead of installing shared-components, since we don't want it as a dependency
 */

import styled from "styled-components/macro";
import { createBoxVariantComponents } from "./setup-factories";

type SpinnerProp = {
  color?: string;
};

type AnimationDelayed = {
  animationDelay: number;
};

const { BoxCubeBounceWrapper } = createBoxVariantComponents({
  CubeBounceWrapper: {
    display: "block",
    width: "40px",
    height: "40px",
  },
});

const CubeBounceItem = styled.div<SpinnerProp & AnimationDelayed>`
  @keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  width: 33%;
  height: 33%;
  background-color: ${(props) => props.color ?? "#333"};
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation-delay: ${(props) => props.animationDelay}s;
`;

export const Spinner = (props: SpinnerProp) => (
  <BoxCubeBounceWrapper>
    <CubeBounceItem {...props} animationDelay={0.2} />
    <CubeBounceItem {...props} animationDelay={0.3} />
    <CubeBounceItem {...props} animationDelay={0.4} />
    <CubeBounceItem {...props} animationDelay={0.1} />
    <CubeBounceItem {...props} animationDelay={0.2} />
    <CubeBounceItem {...props} animationDelay={0.3} />
    <CubeBounceItem {...props} animationDelay={0.0} />
    <CubeBounceItem {...props} animationDelay={0.1} />
    <CubeBounceItem {...props} animationDelay={0.2} />
  </BoxCubeBounceWrapper>
);
