import {
  FC,
  memo,
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";

interface GlobalMessageContextType {
  message:
    | {
        type: "none";
      }
    | {
        type: "unauthorized-action";
      };
  setMessage: (newError: GlobalMessageContextType["message"]) => void;
  clearMessage: () => void;
}

const GlobalMessageContext = createContext<GlobalMessageContextType>({
  message: { type: "none" },
  setMessage: () => {
    throw new Error(
      `Cannot call setMessage outside provider for ForbiddenServiceHandlerContext`
    );
  },
  clearMessage: () => {
    throw new Error(
      `Cannot call clearMessage outside provider for ForbiddenServiceHandlerContext`
    );
  },
});

export const ForbiddenServiceHandlerContextProvider: FC = memo(
  ({ children }) => {
    const [message, setMessage] = useState<GlobalMessageContextType["message"]>(
      { type: "none" }
    );
    const clearMessage = useCallback(
      () => setMessage({ type: "none" } as const),
      []
    );

    const value = useMemo(
      () => ({
        message,
        setMessage,
        clearMessage,
      }),
      [clearMessage, message]
    );

    return (
      <GlobalMessageContext.Provider value={value}>
        {children}
      </GlobalMessageContext.Provider>
    );
  }
);

export const useGlobalMessage = () => {
  const { message, clearMessage, setMessage } =
    useContext(GlobalMessageContext);

  return useMemo(
    () => ({
      globalMessage: message,
      setGlobalMessage: setMessage,
      clearGlobalMessage: clearMessage,
    }),
    [clearMessage, message, setMessage]
  );
};
