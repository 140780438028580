import { lazy, FC } from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";

const UpdateData = lazy(() =>
  import("./UpdateData").then((exports) => ({ default: exports.UpdateData }))
);
const NotFound = lazy(() =>
  import("../Page404").then((exports) => ({ default: exports.Page404 }))
);

export const UpdateDataRoutes: FC<RouteComponentProps> = ({ match: { path } }) => (
  <Switch>
    <Route exact path={`${path}/overview/`} component={UpdateData} />

    <Redirect exact path={path} to={`${path}/overview`} />

    <Route component={NotFound} />
  </Switch>
);