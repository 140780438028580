import { createMostlyFluidComponents } from "./setup-factories";

export const {
  MostlyFluidLayoutApart,
  MostlyFluidLayoutApartPadded,
  MostlyFluidLayoutApartPaddedSmall,
} = createMostlyFluidComponents(
  {
    justifyContent: "space-between",
  },
  {
    LayoutApart: {},
    LayoutApartPadded: {
      horizontalPadding: "24px",
      verticalPadding: "24px",
    },
    LayoutApartPaddedSmall: {
      horizontalPadding: "24px",
      verticalPadding: "8px",
    },
  }
);
export const { MostlyFluidLayoutRight, MostlyFluidLayoutCenter } =
  createMostlyFluidComponents(
    {
      justifyContent: "flex-end",
    },
    {
      LayoutRight: {},
      LayoutCenter: { justifyContent: "center" },
    }
  );
