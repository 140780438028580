export const GLOBALS = {
  font: {
    roboto: {
      family: "'Roboto', sans-serif",
    },
  },

  color: {
    grey: {
      "100": "#FAFAFA",
      "200": "#E0E0E0",
      "300": "#C6C6C6",
      "400": "#ACACAC",
      "500": "#929292",
      "600": "#717171",
      "700": "#515151",
      "800": "#303030",
      "900": "#171717",
    },
    onLight: {
      300: "#898989",
      500: "#3D3E3C",
    },
    onDark: {
      500: "#ffffff",
    },
    green: {
      200: "#A8FCDA",
      300: "#9EEDCD",
      400: "#92DBBD",
      500: "#84C6AB",
      600: "#77B29A",
      700: "#6BA18B",
      800: "#60907D",
      900: "#588573",
    },
    overdueYellow: {
      500: "#FFE79A",
    },
    errorRed: {
      400: "#a14734",
      500: "#E16868",
      600: "#F24638",
    },
    redTranslucent: {
      500: "rgba(193,0,0,0.2)",
    },
    dangerRed: {
      300: "#FF9324",
      400: "#F78E23",
      500: "#E48320",
      600: "#CF771D",
      700: "#BD6D1B",
      800: "#AD6419",
      900: "#A15D17",
    },
    rose: {
      400: "#E8DACB",
      500: "#E8CBCB",
      600: "#e0b5b5",
      700: "#c68b84",
    },
    sand: {
      500: "#e5d3bf",
    },
    mercury: {
      400: "#DBE5D9",
      500: "#D9E5E2",
    },
    eggplantPurple: {
      100: "#9580DE",
      200: "#8C79D1",
      300: "#8271C2",
      400: "#7666B0",
      500: "#685A9B",
      600: "#5B4E87",
      700: "#4F4475",
      800: "#453B66",
      900: "#3C3459",
    },
    hyperlinks: {
      500: "#898989",
      700: "#515151",
    },
    purple: {
      200: "#A2A1B0",
      500: "#76738A",
    },
  },

  shadow: {
    default: "0 0 8px 0 rgba(0,0,0,0.18);",
  },

  transition: {
    default: { duration: "200ms", function: "cubic-bezier(0.33, 1, 0.68, 1)" },
    snappy: { duration: "100ms", function: "cubic-bezier(0.33, 1, 0.68, 1)" },
  },

  zIndex: {
    offCanvas: 100,
    get offCanvasChildren() {
      return GLOBALS.zIndex.offCanvas - 1;
    },
    header: 1000,
  },
} as const;
