import { FC, memo, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { TextBaseLight } from "../../../components/Text";
import { Toast } from "../../../components/Toast";
import { useGlobalMessage } from "../../../util/GlobalMessageContext";

export const GlobalMessage: FC = memo(() => {
  const { globalMessage, clearGlobalMessage } = useGlobalMessage();
  const { pathname } = useLocation();

  // clear the message whenever the user navigates
  useEffect(() => {
    clearGlobalMessage();
  }, [clearGlobalMessage, pathname]);

  // scroll to top whenever we get a non-none message (i.e. something that should be shown)
  useEffect(() => {
    if (globalMessage.type !== "none") {
      return; // do nothing
    }

    window.scrollTo({ top: 0 });
  }, [globalMessage]);

  const message = useMemo(() => {
    switch (globalMessage.type) {
      case "none":
        return null;

      case "unauthorized-action":
        return <GlobalMessageUnauthorizedAction />;

      default:
        // @ts-expect-error - globalMessage.type should be never here
        throw new TypeError(`Unknown type: ${globalMessage.type}`);
    }
  }, [globalMessage.type]);

  return message;
});

const GlobalMessageUnauthorizedAction: FC = memo(() => (
  <Toast type="element" color="danger">
    <TextBaseLight>
      <strong>Error:</strong> You do not have permissions to do that
    </TextBaseLight>
  </Toast>
));
