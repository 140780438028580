import { createBoxComponents } from "./setup-factories";

export const {
  // top
  BoxMarginTop2px,
  BoxMarginTop4px,
  BoxMarginTop8px,
  BoxMarginTop12px,
  BoxMarginTop16px,
  BoxMarginTop24px,
  BoxMarginTop32px,
  BoxMarginTop40px,
  BoxMarginTop48px,
  BoxMarginTop64px,

  // bottom
  BoxMarginBottom2px,
  BoxMarginBottom4px,
  BoxMarginBottom8px,
  BoxMarginBottom12px,
  BoxMarginBottom16px,
  BoxMarginBottom24px,
  BoxMarginBottom32px,
  BoxMarginBottom40px,
  BoxMarginBottom48px,
  BoxMarginBottom64px,

  // right
  BoxMarginRight2px,
  BoxMarginRight4px,
  BoxMarginRight8px,
  BoxMarginRight12px,
  BoxMarginRight16px,
  BoxMarginRight24px,
  BoxMarginRight32px,
  BoxMarginRight40px,
  BoxMarginRight48px,
  BoxMarginRight64px,
} = createBoxComponents(
  {
    flexDirection: "column",
  },
  {
    // top
    MarginTop2px: { margin: { top: "2px" } },
    MarginTop4px: { margin: { top: "4px" } },
    MarginTop8px: { margin: { top: "8px" } },
    MarginTop12px: { margin: { top: "12px" } },
    MarginTop16px: { margin: { top: "16px" } },
    MarginTop24px: { margin: { top: "24px" } },
    MarginTop32px: { margin: { top: "32px" } },
    MarginTop40px: { margin: { top: "40px" } },
    MarginTop48px: { margin: { top: "48px" } },
    MarginTop64px: { margin: { top: "64px" } },

    // bottom
    MarginBottom2px: { margin: { bottom: "2px" } },
    MarginBottom4px: { margin: { bottom: "4px" } },
    MarginBottom8px: { margin: { bottom: "8px" } },
    MarginBottom12px: { margin: { bottom: "12px" } },
    MarginBottom16px: { margin: { bottom: "16px" } },
    MarginBottom24px: { margin: { bottom: "24px" } },
    MarginBottom32px: { margin: { bottom: "32px" } },
    MarginBottom40px: { margin: { bottom: "40px" } },
    MarginBottom48px: { margin: { bottom: "48px" } },
    MarginBottom64px: { margin: { bottom: "64px" } },

    // bottom
    MarginRight2px: { margin: { right: "2px" } },
    MarginRight4px: { margin: { right: "4px" } },
    MarginRight8px: { margin: { right: "8px" } },
    MarginRight12px: { margin: { right: "12px" } },
    MarginRight16px: { margin: { right: "16px" } },
    MarginRight24px: { margin: { right: "24px" } },
    MarginRight32px: { margin: { right: "32px" } },
    MarginRight40px: { margin: { right: "40px" } },
    MarginRight48px: { margin: { right: "48px" } },
    MarginRight64px: { margin: { right: "64px" } },
  }
);
