import { GLOBALS } from "./globals";
import { createBoxVariantComponents } from "./setup-factories";

const SPACING = "24px";

export const { BoxCard, BoxCardContent, BoxCardDivider, BoxCardFooter } =
  createBoxVariantComponents({
    Card: {
      tagName: "section",
      flexGrow: 0,
      flexShrink: 1,
      width: "100%",
      flexBasis: "100%",
      flexDirection: "column",
      height: "100%",
      background: { color: "white" },
      boxShadow: {
        x: "0px",
        y: "10px",
        blur: "30px",
        spread: "0px",
        color: "rgba(0,0,0,0.07)",
      },
      border: {
        width: "0px",
        radius: {
          bottomLeft: "20px",
          bottomRight: "20px",
          topLeft: "20px",
          topRight: "20px",
        },
      },
    },
    CardContent: {
      flexDirection: "column",
      flexGrow: 1,
      padding: {
        top: "0px",
        right: SPACING,
        bottom: "0px",
        left: SPACING,
      },
      margin: {
        top: SPACING,
        bottom: SPACING,
      },
    },
    CardDivider: {
      tagName: "hr",
      border: { style: "none" },
      background: { color: GLOBALS.color.grey[100] },
      height: "1px",
      margin: {
        bottom: SPACING,
      },
    },
    CardFooter: {
      flexDirection: "row-reverse",
      padding: {
        top: SPACING,
        right: SPACING,
        bottom: SPACING,
        left: SPACING,
      },
      border: {
        width: "1px",
        style: {
          top: "solid",
          right: "none",
          bottom: "none",
          left: "none",
        },
        color: GLOBALS.color.grey[100],
      },
    },
  });
