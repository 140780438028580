import {
  ComponentProps,
  ComponentType,
  FC,
  useCallback,
  MouseEvent,
} from "react";
import { Link, useHistory } from "react-router-dom";

/**
 * Transform a component that extends an anchor (<a/>), to a react router link
 * This will allow default anchor behaviour from the browser (right click, open in new tab, etc), but still enable us to navigate the page, without reloading the site
 * @param AnchorComponent The original anchor which to transform into a react router link
 */
export const asReactRouterLink = <
  AnchorComponentProps extends ComponentProps<"a">
>(
  AnchorComponent: ComponentType<AnchorComponentProps>
) => {
  const TransformedComp: FC<
    ComponentProps<typeof AnchorComponent> & ComponentProps<typeof Link>
  > = (props) => {
    const { to } = props;
    const location = useHistory();

    const onClick = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // CTRL / CMD click opens in new tap
        if (event.getModifierState("Meta")) {
          window.open(to.toString(), "_blank");
        } else {
          location.push(to.toString());
        }

        return false;
      },
      [to, location]
    );

    return <AnchorComponent {...props} href={to} onClick={onClick} />;
  };

  return TransformedComp;
};
