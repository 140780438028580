import { FC, memo } from "react";
import { faSignOut } from "@fortawesome/pro-light-svg-icons/faSignOut";
import { AnchorMenu, AnchorMenuActive } from "../../../components/AnchorMenu";
import { BoxRow } from "../../../components/BoxRow";
import logo from "../../../assets/logo.png";
import { IconMenu } from "../../../components/Icon";
import { ButtonMenu } from "../../../components/ButtonMenu";
import {
  createBoxVariantComponents,
  createMostlyFluidVariantComponents,
} from "../../../components/setup-factories";
import { useMenuItems } from "./use-menu-items";
import useCountryFlag from "./use-flag";
import { useSignOut } from "./use-sign-out";
import { useLocation } from "react-router";

const { BoxContainer, BoxSection } = createBoxVariantComponents({
  Container: {
    position: {
      position: "fixed",
      top: "0px",
      bottom: "0",
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100vh",
    width: "130px",
    border: {
      radius: {
        topRight: "20px",
        bottomRight: "20px",
      },
    },
    background: {
      function: "linear-gradient",
      args: "180deg, #211d44 0%, #171430 117.18%",
    },
    breakpoints: {
      medium: { display: "none" },
      small: { display: "none" },
    },
  },

  Section: {
    margin: { top: "32px", bottom: "32px" },
  },
});

const { MostlyFluidMenuItems, MostlyFluidMenuItem } =
  createMostlyFluidVariantComponents({
    MenuItems: {
      widths: ["100%"],
      verticalPadding: "32px",
    },
    MenuItem: {
      widths: ["100%"],
      verticalPadding: "8px",
    },
  });

export const Menu: FC = memo(() => (
  <BoxContainer style={{ overflowY: "auto" }}>
    <BoxSection>
      <Logo />
    </BoxSection>
    <UseCountryFlag />
    <BoxSection>
      <MenuItems />
    </BoxSection>

    <BoxSection>
      <SignOut />
    </BoxSection>
  </BoxContainer>
));

const UseCountryFlag: FC = memo(() => <img src={useCountryFlag()} width="24" height="24" alt="Flag" />);
const Logo: FC = memo(() => <img src={logo} alt="Sini" />);

const MenuItems: FC = memo(() => {
  const { menuItems } = useMenuItems();
  const { pathname } = useLocation();

  return (
    <MostlyFluidMenuItems>
      {menuItems.map((menuItem) => {
        const Anchor = menuItem.isActive(pathname)
          ? AnchorMenuActive
          : AnchorMenu;

        return (
          <Anchor key={menuItem.href} to={menuItem.href}>
            <MostlyFluidMenuItem>
              <IconMenu icon={menuItem.icon} />
              <span>{menuItem.label}</span>
            </MostlyFluidMenuItem>
          </Anchor>
        );
      })}
    </MostlyFluidMenuItems>
  );
});

const SignOut: FC = memo(() => {
  const { signOut } = useSignOut();

  return (
    <ButtonMenu type="button" onClick={signOut}>
      <BoxRow>
        <IconMenu icon={faSignOut} />
      </BoxRow>
    </ButtonMenu>
  );
});
