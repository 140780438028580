import { createBoxComponents } from "./setup-factories";

export const { BoxBlock, BoxBlockCenter, BoxBlockBaseline, BoxBlockBottom } =
  createBoxComponents(
    {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    {
      Block: {},
      BlockCenter: { alignItems: "center" },
      BlockBaseline: { alignItems: "baseline" },
      BlockBottom: { alignItems: "flex-end" },
    }
  );
