import { GLOBALS } from "./globals";
import { createHeadingVariantComponents } from "./setup-factories";

export const { HeadingCard } = createHeadingVariantComponents({
  Card: {
    font: {
      family: GLOBALS.font.roboto.family,
      size: "18px",
      weight: 700,
      lineHeight: "100%",
      color: GLOBALS.color.onLight[500],
    },
  },
});
