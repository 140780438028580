import { FC, memo } from "react";
import { createTextComponents } from "./setup-factories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

const {
  TextNormalInternal,
  TextLargeInternal,
  TextMenuInternal,
  TextHugeInternal,
} = createTextComponents(
  {
    font: { size: "16px", lineHeight: "16px" },
  },
  {
    NormalInternal: {},
    LargeInternal: {
      font: { size: "20px", lineHeight: "20px" },
    },
    MenuInternal: {
      font: { size: "24px", lineHeight: "24px" },
    },
    HugeInternal: {
      font: { size: "84px", lineHeight: "84px" },
    },
  }
);

interface BoxIconProps {
  icon: IconDefinition;
}

export const Icon: FC<BoxIconProps> = memo(({ icon, ...props }) => {
  return (
    <TextNormalInternal {...props}>
      <FontAwesomeIcon icon={icon} fixedWidth={true} />
    </TextNormalInternal>
  );
});

export const IconLarge: FC<BoxIconProps> = memo(({ icon, ...props }) => {
  return (
    <TextLargeInternal {...props}>
      <FontAwesomeIcon icon={icon} fixedWidth={true} />
    </TextLargeInternal>
  );
});
export const IconMenu: FC<BoxIconProps> = memo(({ icon, ...props }) => {
  return (
    <TextMenuInternal {...props}>
      <FontAwesomeIcon icon={icon} fixedWidth={true} />
    </TextMenuInternal>
  );
});
export const IconHuge: FC<BoxIconProps> = memo(({ icon, ...props }) => {
  return (
    <TextHugeInternal {...props}>
      <FontAwesomeIcon icon={icon} fixedWidth={true} />
    </TextHugeInternal>
  );
});
