import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { ForbiddenServiceHandlerContextProvider } from "./util/GlobalMessageContext";

ReactDOM.render(
  <StrictMode>
    <ForbiddenServiceHandlerContextProvider>
      <App />
    </ForbiddenServiceHandlerContextProvider>
  </StrictMode>,
  document.getElementById("root")
);
