import { useMemo, useCallback } from "react";

export const useSignOut = () => {
  const signOut = useCallback(() => {
    window.document.cookie =
      "userSession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    window.document.cookie = `userSession=; Domain=.${window.location.host}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    window.location.pathname = "/login";
  }, []);

  return useMemo(
    () => ({
      signOut,
    }),
    [signOut]
  );
};
