import { FC, useCallback, useState, memo, useEffect } from "react";
import { asReactRouterLink } from "../../../util/asReactRouterLink";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { useLocation } from "react-router";
import logo from "../../../assets/logo.png";
import { MostlyFluidLayoutApart } from "../../../components/MostlyFluidLayout";
import {
  createAnchorComponents,
  createBoxComponents,
  createBoxVariantComponents,
  createButtonVariantComponents,
  createOffViewportComponents,
} from "../../../components/setup-factories";
import { GLOBALS } from "../../../components/globals";
import { useMenuItems } from "./use-menu-items";
import { useSignOut } from "./use-sign-out";
import useCountryFlag from "./use-flag";

const { OffViewport } = createOffViewportComponents({
  direction: "right",
  backdropColor: GLOBALS.color.grey[900],
  length: "100%",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.04)",
  breakpoints: {
    xlarge: {},
    large: {},
    medium: {},
    small: {},
  },
});

const { BoxMenuWrapper, BoxHeader, BoxContent } = createBoxComponents(
  {},
  {
    MenuWrapper: {
      height: { default: "auto", min: "100%" },
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      background: { color: "white" },
      padding: { bottom: "10px" },
      alignContent: "flex-start",
    },
    Header: {
      width: "100%",
      height: "64px",
      background: { color: "white" },
      padding: { top: "7px", right: "16px", bottom: "5px", left: "16px" },
      boxShadow: GLOBALS.shadow.default,
    },
    Content: {
      display: "block",
      width: "100%",
      padding: { top: "10px", right: "15px", left: "15px" },
    },
  }
);

const { AnchorMenuItemActiveInternal, AnchorMenuItemInactiveInternal } =
  createAnchorComponents(
    {
      border: {
        style: { bottom: "solid" },
        width: "1px",
        color: GLOBALS.color.grey[200],
      },
      font: {
        family: GLOBALS.font.roboto.family,
        decoration: "none",
        size: "16px",
        weight: 400,
        lineHeight: "23px",
      },
      padding: { top: "16px", right: "0", bottom: "14px", left: "0" },
      $when: {
        active: {},
        focus: {},
        hover: {},
        link: {},
        nolink: {},
        visited: {},
      },
    },
    {
      MenuItemActiveInternal: {
        font: {
          color: GLOBALS.color.eggplantPurple[200],
        },
      },
      MenuItemInactiveInternal: {
        font: {
          color: GLOBALS.color.onLight[500],
        },
      },
    }
  );

const { ButtonMenuItemInactive } = createButtonVariantComponents({
  MenuItemInactive: {
    width: "100%",
    justifyContent: "flex-start",
    border: {
      style: { bottom: "solid" },
      width: "1px",
      color: GLOBALS.color.grey[200],
    },
    background: { color: "white" },
    font: {
      family: GLOBALS.font.roboto.family,
      decoration: "none",
      size: "16px",
      weight: 400,
      lineHeight: "23px",
      color: GLOBALS.color.onLight[500],
    },
    padding: { top: "16px", right: "0", bottom: "14px", left: "0" },
    $when: {
      active: {},
      focus: {},
      hover: {},
      disabled: {},
    },
  },
});

const AnchorMenuItemActiveInternalStyled = styled(AnchorMenuItemActiveInternal)`
  display: block;
`;
const AnchorMenuItemInactiveInternalStyled = styled(
  AnchorMenuItemInactiveInternal
)`
  display: block;
`;

const { ButtonIcon } = createButtonVariantComponents({
  Icon: {
    padding: "10px",
    background: { color: "transparent" },
    font: { color: GLOBALS.color.onLight[500], size: "16px" },
    transition: { color: GLOBALS.transition.default },
    $when: {
      active: {},
      disabled: {},
      focus: {},
      hover: { font: { color: GLOBALS.color.grey[600] } },
    },
  },
});

const { BoxLogoWrapper } = createBoxVariantComponents({
  LogoWrapper: {
    height: "100%",
    alignItems: "center",
  },
});
const Logo = styled.img`
  height: 42px;
`;

const UseCountryFlag = styled.img`
  height: 25px;
`;

const AnchorMenuItemActive = asReactRouterLink(
  AnchorMenuItemActiveInternalStyled
);
const AnchorMenuItemInactive = asReactRouterLink(
  AnchorMenuItemInactiveInternalStyled
);

interface AnchorMenuItemProps {
  active: boolean;
  to: string;
}
const AnchorMenuItem: FC<AnchorMenuItemProps> = memo(
  ({ active, to, children }) =>
    active === true ? (
      <AnchorMenuItemActive to={to}>{children}</AnchorMenuItemActive>
    ) : (
      <AnchorMenuItemInactive to={to}>{children}</AnchorMenuItemInactive>
    )
);

export const BurgerMenu: FC = () => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((old) => !old), [setOpen]);
  const { pathname } = useLocation();
  const { menuItems } = useMenuItems();
  const { signOut } = useSignOut();

  useEffect(() => {
    setOpen((open) => {
      if (open) {
        return false;
      } else {
        return open;
      }
    });
  }, [pathname]);

  return (
    <>
      <ButtonIcon onClick={toggle} type="button">
        <FontAwesomeIcon icon={faBars} size={"2x"} />
      </ButtonIcon>
      <OffViewport open={open} setOpen={setOpen}>
        <BoxMenuWrapper>
          <BoxHeader>
            <MostlyFluidLayoutApart>
              <BoxLogoWrapper>
                <Logo src={logo} alt="Sini" />
              </BoxLogoWrapper>
              <BoxLogoWrapper>
                <UseCountryFlag src={useCountryFlag()} alt="Flag" />
              </BoxLogoWrapper>
              <ButtonIcon onClick={toggle} type="button">
                <FontAwesomeIcon icon={faTimes} size={"2x"} />
              </ButtonIcon>
            </MostlyFluidLayoutApart>
          </BoxHeader>

          <BoxContent>
            {menuItems.map((menuItem) => (
              <AnchorMenuItem
                key={menuItem.href}
                active={menuItem.isActive(pathname)}
                to={menuItem.href}
              >
                {menuItem.label}
              </AnchorMenuItem>
            ))}

            <ButtonMenuItemInactive type="button" onClick={signOut}>
              Log out
            </ButtonMenuItemInactive>
          </BoxContent>
        </BoxMenuWrapper>
      </OffViewport>
    </>
  );
};
