import { GLOBALS } from "./globals";
import { createButtonComponents } from "./setup-factories";

export const { Button, ButtonSecondary, ButtonDanger, ButtonGhost } =
  createButtonComponents(
    {
      padding: { top: "14px", right: "14px", bottom: "11px", left: "14px" },
      font: {
        family: GLOBALS.font.roboto.family,
        size: "16px",
        weight: 400,
        lineHeight: "19px",
        color: GLOBALS.color.onDark[500],
      },
      border: {
        width: "3px",
        radius: "6px",
        style: { top: "none", right: "none", bottom: "solid", left: "none" },
        color: GLOBALS.color.eggplantPurple[600],
      },
      background: { color: GLOBALS.color.eggplantPurple[500] },

      $when: {
        disabled: {
          font: { color: GLOBALS.color.grey[500] },
          background: { color: GLOBALS.color.grey[200] },
          border: { color: GLOBALS.color.grey[200] },
        },
        focus: {
          font: { color: GLOBALS.color.onDark[500] },
          background: { color: GLOBALS.color.eggplantPurple[600] },
          border: { color: GLOBALS.color.eggplantPurple[700] },
        },
        hover: {
          font: { color: GLOBALS.color.onDark[500] },
          background: { color: GLOBALS.color.eggplantPurple[600] },
          border: { color: GLOBALS.color.eggplantPurple[700] },
        },
        active: {
          font: { color: GLOBALS.color.onDark[500] },
          background: { color: GLOBALS.color.eggplantPurple[800] },
          border: { color: GLOBALS.color.eggplantPurple[800] },
        },
      },
    },
    {
      Secondary: {
        font: { color: GLOBALS.color.onDark[500] },
        background: { color: GLOBALS.color.green[500] },
        border: { color: GLOBALS.color.green[600] },
        $when: {
          disabled: {
            font: { color: GLOBALS.color.grey[500] },
            background: { color: GLOBALS.color.grey[200] },
            border: { color: GLOBALS.color.grey[200] },
          },
          focus: {
            font: { color: GLOBALS.color.onDark[500] },
            background: { color: GLOBALS.color.green[600] },
            border: { color: GLOBALS.color.green[700] },
          },
          hover: {
            font: { color: GLOBALS.color.onDark[500] },
            background: { color: GLOBALS.color.green[600] },
            border: { color: GLOBALS.color.green[700] },
          },
          active: {
            font: { color: GLOBALS.color.onDark[500] },
            background: { color: GLOBALS.color.green[800] },
            border: { color: GLOBALS.color.green[800] },
          },
        },
      },
      Danger: {
        background: { color: GLOBALS.color.dangerRed[500] },
        border: { color: GLOBALS.color.dangerRed[600] },
        $when: {
          disabled: {},
          focus: {
            background: { color: GLOBALS.color.dangerRed[600] },
            border: { color: GLOBALS.color.dangerRed[700] },
          },
          hover: {
            background: { color: GLOBALS.color.dangerRed[600] },
            border: { color: GLOBALS.color.dangerRed[700] },
          },
          active: {
            background: { color: GLOBALS.color.dangerRed[800] },
            border: { color: GLOBALS.color.dangerRed[800] },
          },
        },
      },
      Ghost: {
        padding: { top: "13px", right: "14px", bottom: "13px", left: "14px" },
        border: {
          width: "1px",
          style: "solid",
          color: GLOBALS.color.grey[200],
        },
        background: { color: "white" },
        font: { color: GLOBALS.color.onLight[500] },
        $when: {
          disabled: {
            font: { color: GLOBALS.color.grey[400] },
            background: { color: GLOBALS.color.grey[200] },
            border: { color: GLOBALS.color.grey[200] },
          },
          focus: {
            border: { color: GLOBALS.color.grey[500] },
            font: { color: GLOBALS.color.onLight[500] },
            background: { color: "white" },
          },
          hover: {
            border: { color: GLOBALS.color.grey[600] },
            font: { color: GLOBALS.color.onLight[500] },
            background: { color: "white" },
          },
          active: {
            border: { color: GLOBALS.color.grey[800] },
            font: { color: GLOBALS.color.onLight[500] },
            background: { color: "white" },
          },
        },
      },
    }
  );
