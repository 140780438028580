import { FC, memo } from "react";
import { createBoxVariantComponents } from "../components/setup-factories";
import { TextParagraph } from "../components/Text";
import { HeadingSection } from "../components/HeadingSection";
import { MostlyFluidSpacedVertical12px } from "../components/MostlyFluidSpaced";

const { BoxCenterContainer } = createBoxVariantComponents({
  CenterContainer: {
    height: { min: "50vh" },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});
export const PageGenericError: FC = memo(() => (
  <BoxCenterContainer>
    <div>
      <MostlyFluidSpacedVertical12px>
        <HeadingSection tag="h1">Error</HeadingSection>
        <TextParagraph>
          Please try again later or contact a system administrator.
        </TextParagraph>
      </MostlyFluidSpacedVertical12px>
    </div>
  </BoxCenterContainer>
));
