import { useUserInformation } from "../../../util/useUserInformation";
import ReactLogoDenmark from '../../../assets/denmark-flag-icon.svg';
import ReactLogoNorway from '../../../assets/norway-flag-icon.svg';
import ReactLogoNetherlands from '../../../assets/netherlands-flag-icon.svg';
import ReactLogoPoland from '../../../assets/poland-flag-icon.svg';
import ReactLogoSweden from '../../../assets/sweden-flag-icon.svg';
import ReactLogoSwitzerland from '../../../assets/switzerland-flag-icon.svg';
import ReactLogoGermany from '../../../assets/germany-flag-icon.svg';
import NoFlag from '../../../assets/no-flag.svg';

const useCountryFlag = () => {
  const { region: { regionKey } } = useUserInformation();

  switch (regionKey) {
    case 'reg1onlnkmlsp7kq':
      return ReactLogoDenmark;
    case 'reg1onlnkmlsp7kx':
      return ReactLogoGermany;
    case 'reg1onlnkmlsp7ks':
      return ReactLogoNorway;
    case 'reg1onlnkmlsp7ku':
      return ReactLogoNetherlands;
    case 'reg1onlnkmlsp7ke':
      return ReactLogoPoland;
    case 'reg1onlnkmlsp7ko':
      return ReactLogoSweden;
    case 'reg1onlnkmlsp7ka':
      return ReactLogoSwitzerland;
    default:
      return NoFlag;
  }
}

export default useCountryFlag;