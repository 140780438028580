import { Spinner } from "../components/Spinner";
import { GLOBALS } from "./globals";
import { createBoxVariantComponents } from "./setup-factories";

const { BoxCenterContainer } = createBoxVariantComponents({
  CenterContainer: {
    height: { min: "50vh" },
    alignItems: "center",
    justifyContent: "center",
  },
});
export const CenteredLoadingIndicator = () => (
  <BoxCenterContainer>
    <Spinner color={GLOBALS.color.green[300]} />
  </BoxCenterContainer>
);
