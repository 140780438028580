import { GLOBALS } from "./globals";
import { createTextComponents } from "./setup-factories";

export const { TextParagraph, TextParagraphFaded, TextParagraphReadable } =
  createTextComponents(
    {
      tag: "p",
      font: {
        family: GLOBALS.font.roboto.family,
        size: "16px",
        lineHeight: "19px",
        weight: 400,
        color: GLOBALS.color.onLight[500],
      },
    },
    {
      Paragraph: {},
      ParagraphFaded: {
        font: {
          size: "14px",
          lineHeight: "100%",
          color: GLOBALS.color.onLight[300],
        },
      },
      ParagraphReadable: {
        font: {
          color: GLOBALS.color.onLight[500],
          lineHeight: "24px",
        },
      },
    }
  );

export const { TextBase, TextBaseLight } = createTextComponents(
  {
    font: {
      family: GLOBALS.font.roboto.family,
      size: "16px",
      lineHeight: "19px",
      weight: 400,
      color: GLOBALS.color.onLight[500],
    },
    tag: "span",
  },
  {
    Base: {},
    BaseLight: {
      font: {
        color: GLOBALS.color.onDark[500],
      },
    },
  }
);
