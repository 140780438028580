import { GLOBALS } from "../../../components/globals";
import styled from "styled-components/macro";
import { createBoxVariantComponents } from "../../../components/setup-factories";
import { FC, memo } from "react";
import logo from "../../../assets/logo.png";
import { BurgerMenu } from "./BurgerMenu";
import { MostlyFluidLayoutApart } from "../../../components/MostlyFluidLayout";
import useCountryFlag from "./use-flag";

const { BoxHeaderInternal } = createBoxVariantComponents({
  HeaderInternal: {
    display: "none",
    position: { position: "fixed", top: "0", left: "0", right: "0" },
    zIndex: GLOBALS.zIndex.header,
    height: "64px",
    background: { color: "white" },
    alignItems: "center",
    justifyContent: "space-between",
    padding: { top: "1px", left: "16px", right: "16px" },
    boxShadow: GLOBALS.shadow.default,
    breakpoints: {
      medium: { display: "flex" },
      small: { display: "flex" },
    },
  },
});

const Logo = styled.img`
  height: 42px;
`;
const UseCountryFlag = styled.img`
  height: 25px;
`;

const { BoxLogoWrapper } = createBoxVariantComponents({
  LogoWrapper: {
    height: "100%",
    alignItems: "center",
  },
});

export const Header: FC = memo(() => {
  return (
    <BoxHeaderInternal>
      <MostlyFluidLayoutApart>
        <BoxLogoWrapper>
          <Logo src={logo} alt="Sini" />
        </BoxLogoWrapper>
        <BoxLogoWrapper>
          <UseCountryFlag src={useCountryFlag()} alt="Flag" />
        </BoxLogoWrapper>
        <BurgerMenu />
      </MostlyFluidLayoutApart>
    </BoxHeaderInternal>
  );
});
