import { GLOBALS } from "./globals";
import { createButtonVariantComponents } from "./setup-factories";

export const { ButtonMenu } = createButtonVariantComponents({
  Menu: {
    font: {
      family: GLOBALS.font.roboto.family,
      color: GLOBALS.color.purple[500],
      size: "16px",
      lineHeight: "100%",
      weight: 500,
    },
    padding: { top: "0px", right: "0px", bottom: "4px", left: "0px" },
    border: { style: "none", width: "0" },
    background: { color: "transparent" },
    $when: {
      disabled: {},
      focus: {},
      hover: {
        font: { color: GLOBALS.color.purple[200] },
      },
      active: {},
    },
  },
});
