import { setupFactories } from "component-factories/build/setup-factories";
import { FactoryButton } from "component-factories/build/factories/buttons/Button";
import { FactoryAnchor } from "component-factories/build/factories/typographies/Anchor";
import { FactoryBox } from "component-factories/build/factories/layouts/Box";
import { FactoryHeader } from "component-factories/build/factories/layouts/Header";
import { FactoryFooter } from "component-factories/build/factories/layouts/Footer";
import { FactoryMostlyFluid } from "component-factories/build/factories/layouts/MostlyFluid";
import { FactoryHeading } from "component-factories/build/factories/typographies/Heading";
import { FactoryText } from "component-factories/build/factories/typographies/Text";
import { FactoryTable } from "component-factories/build/factories/structures/Table";
import { FactoryFieldText } from "component-factories/build/factories/inputs/FieldText";
import { FactoryFieldNumber } from "component-factories/build/factories/inputs/FieldNumber";
import { FactoryFieldTextArea } from "component-factories/build/factories/inputs/FieldTextArea";
import { FactoryOffViewport } from "component-factories/build/factories/layouts/OffViewport";
import { FactoryOffCanvas } from "component-factories/build/factories/layouts/OffCanvas";
import { FactoryInputFile } from "component-factories/build/factories/inputs/InputFile";
import { FactoryFieldSelect } from "component-factories/build/factories/inputs/FieldSelect";
import { FactoryFieldMultiselect } from "component-factories/build/factories/inputs/FieldMultiselect";
import { FactoryColumnDrop } from "component-factories/build/factories/layouts/ColumnDrop";

export const {
  createBoxComponents,
  createBoxVariantComponents,
  createButtonComponents,
  createButtonVariantComponents,
  createAnchorComponents,
  createAnchorVariantComponents,
  createHeaderComponents,
  createHeaderVariantComponents,
  createFooterComponents,
  createFooterVariantComponents,
  createMostlyFluidComponents,
  createMostlyFluidVariantComponents,
  createHeadingComponents,
  createHeadingVariantComponents,
  createTextComponents,
  createTextVariantComponents,
  createTableComponents,
  createTableVariantComponents,
  createFieldTextComponents,
  createFieldTextVariantComponents,
  createFieldNumberComponents,
  createFieldNumberVariantComponents,
  createFieldTextAreaComponents,
  createFieldTextAreaVariantComponents,
  createOffViewportComponents,
  createOffViewportVariantComponents,
  createOffCanvasComponents,
  createOffCanvasVariantComponents,
  createInputFileComponents,
  createInputFileVariantComponents,
  createFieldSelectComponents,
  createFieldSelectVariantComponents,
  createFieldMultiselectComponents,
  createFieldMultiselectVariantComponents,
  createColumnDropComponents,
  createColumnDropVariantComponents,
  BREAKPOINTS,
} = setupFactories(
  {
    Box: FactoryBox,
    Button: FactoryButton,
    Anchor: FactoryAnchor,
    Header: FactoryHeader,
    Footer: FactoryFooter,
    MostlyFluid: FactoryMostlyFluid,
    Heading: FactoryHeading,
    Text: FactoryText,
    Table: FactoryTable,
    FieldText: FactoryFieldText,
    FieldNumber: FactoryFieldNumber,
    FieldTextArea: FactoryFieldTextArea,
    OffViewport: FactoryOffViewport,
    OffCanvas: FactoryOffCanvas,
    InputFile: FactoryInputFile,
    FieldSelect: FactoryFieldSelect,
    FieldMultiselect: FactoryFieldMultiselect,
    ColumnDrop: FactoryColumnDrop,
  },
  { xlarge: 1300, large: 1000, medium: 500, small: 0 }
);
