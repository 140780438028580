import {useMemo} from "react";
import {faUsers} from "@fortawesome/pro-light-svg-icons/faUsers";
import {faLink} from "@fortawesome/pro-light-svg-icons/faLink";
import {faQuestion} from "@fortawesome/pro-light-svg-icons/faQuestion";
import {faShoppingCart} from "@fortawesome/pro-light-svg-icons/faShoppingCart";
import {faLuggageCart} from "@fortawesome/pro-light-svg-icons/faLuggageCart";
import {faClipboardList} from "@fortawesome/pro-light-svg-icons/faClipboardList";
import {faBorderAll} from "@fortawesome/pro-light-svg-icons/faBorderAll";
import {faCalculator} from "@fortawesome/pro-light-svg-icons/faCalculator";
import {faInventory} from "@fortawesome/pro-light-svg-icons/faInventory";
// import {faScanner} from "@fortawesome/pro-light-svg-icons/faScanner";
import {faAddressBook} from "@fortawesome/pro-light-svg-icons/faAddressBook";
import {faAddressCard} from "@fortawesome/pro-light-svg-icons/faAddressCard";
import {faChartNetwork} from "@fortawesome/pro-light-svg-icons/faChartNetwork";
// import { faNewspaper } from "@fortawesome/pro-light-svg-icons/faNewspaper";
import {faWarehouseAlt} from "@fortawesome/pro-light-svg-icons/faWarehouseAlt";
import {faTruckMoving} from "@fortawesome/pro-light-svg-icons/faTruckMoving";
import {faAbacus} from "@fortawesome/pro-light-svg-icons/faAbacus";
import {faBoxUsd} from "@fortawesome/pro-light-svg-icons/faBoxUsd";
// import {faAlarmExclamation} from "@fortawesome/pro-light-svg-icons/faAlarmExclamation";
// import {faTruckLoading} from "@fortawesome/pro-light-svg-icons/faTruckLoading";
// import { faCloudDownload } from "@fortawesome/pro-light-svg-icons/faCloudDownload";
import {faUsersClass} from "@fortawesome/pro-light-svg-icons/faUsersClass";
import {faUserClock} from "@fortawesome/pro-light-svg-icons/faUserClock";
import {faCarSide} from "@fortawesome/pro-light-svg-icons/faCarSide";
import {faThList} from "@fortawesome/pro-light-svg-icons/faThList";
import {useUserInformation} from "../../../util/useUserInformation";
import {faFolderOpen} from "@fortawesome/pro-light-svg-icons/faFolderOpen";
import {faDatabase} from "@fortawesome/pro-light-svg-icons/faDatabase";

export const useMenuItems = () => {
    const {
        hasAdminReadAccess,
        hasAudiologReadAccess,
        hasWarehouseWorkerReadAccess,
        hasNoRole,
        userKey: audiologistKey,
        region: {regionKey}
    } = useUserInformation();

    return useMemo(
        () => ({
            menuItems: [
                ...(hasAdminReadAccess === true
                    ? [
                        {
                            label: "Overview",
                            icon: faBorderAll,
                            href: "/admin/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/overview"),
                        },
                        // {
                        //   label: "News",
                        //   icon: faNewspaper,
                        //   href: "/admin/news",
                        //   isActive: (pathname: string) =>
                        //     pathname.startsWith("/admin/news"),
                        // },
                        {
                            label: "Reports",
                            icon: faClipboardList,
                            href: "/admin/reports",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/reports"),
                        },
                        {
                            label: "Tid (admin)",
                            icon: faUserClock,
                            href: "/admin/time/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/time"),
                        },
                        {
                            label: "Tid",
                            icon: faUserClock,
                            href: "/all/time/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/all/time"),
                        },
                        {
                            label: "Control",
                            icon: faThList,
                            href: "/mixed/customer-lists/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/mixed/customer-lists"),
                        },
                        {
                            label: "Coworker Status",
                            icon: faUsers,
                            href: "/all/coworker-status",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/all/coworker-status"),
                        },
                        ...(regionKey === "reg1onlnkmlsp7kq"
                            ? [
                                {
                                    label: "Calculator",
                                    icon: faCalculator,
                                    href: "/all/calculator",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/all/calculator"),
                                },
                            ]
                            : []),
                        // {
                        //   label: "Overtime & Leave",
                        //   icon: faUserClock,
                        //   href: "/admin/overtime",
                        //   isActive: (pathname: string) =>
                        //     pathname.startsWith("/admin/overtime/overview"),
                        // },
                        {
                            label: "Data",
                            icon: faDatabase,
                            href: "/mixed/data/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/mixed/data"),
                        },
                        {
                            label: "Ny Ordre",
                            icon: faShoppingCart,
                            href: "/audiologist/order/create-order",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/order/create-order"),
                        },
                        {
                            label: "Mine Ordre",
                            icon: faLuggageCart,
                            href: "/audiologist/order/order-list",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/order/order-list"),
                        },
                        {
                            label: "Documents",
                            icon: faFolderOpen,
                            href: "/admin/products",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/products"),
                        },
                        {
                            label: "Links",
                            icon: faLink,
                            href: "/admin/links",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/links"),
                        },
                        {
                            label: "FAQ",
                            icon: faQuestion,
                            href: "/admin/faqs",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/faqs"),
                        },
                        {
                            label: "Users",
                            icon: faUsers,
                            href: "/admin/users",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/users"),
                        },
                        {
                            label: "Contacts",
                            icon: faAddressCard,
                            href: "/admin/contact-list",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/contact-list"),
                        },
                        {
                            label: "Import Reports",
                            icon: faChartNetwork,
                            href: "/admin/amparex-import-reports",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/admin/amparex-import-reports"),
                        },
                    ]
                    : []),
                ...(hasAudiologReadAccess === true
                    ? [
                        {
                            label: "Overview",
                            icon: faBorderAll,
                            href: "/audiologist/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/overview"),
                        },
                        {
                            label: "Reports",
                            icon: faClipboardList,
                            href: "/audiologist/reports",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/reports"),
                        },
                        ...(hasAdminReadAccess === false
                            ? [
                                {
                                    label: "Coworker Status",
                                    icon: faUsers,
                                    href: "/all/coworker-status",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/all/coworker-status"),
                                },
                            ]
                            : []),
                        ...(regionKey === "reg1onlnkmlsp7kq"
                            ? [
                                {
                                    label: "Calculator",
                                    icon: faCalculator,
                                    href: "/all/calculator",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/all/calculator"),
                                },
                            ]
                            : []),
                        // {
                        //     label: "Overtime & Leave",
                        //     icon: faUserClock,
                        //     href: "/audiologist/overtime/overview",
                        //     isActive: (pathname: string) =>
                        //         pathname.startsWith("/audiologist/overtime"),
                        // },
                        {
                            label: "Tid",
                            icon: faUserClock,
                            href: "/all/time/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/all/time"),
                        },
                        {
                            label: "Ny Ordre",
                            icon: faShoppingCart,
                            href: "/audiologist/order/create-order",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/order/create-order"),
                        },
                        {
                            label: "Mine Ordre",
                            icon: faLuggageCart,
                            href: "/audiologist/order/order-list",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/order/order-list"),
                        },
                        {
                            label: "Data",
                            icon: faDatabase,
                            href: "/mixed/data/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/mixed/data"),
                        },
                        {
                            label: "Documents",
                            icon: faFolderOpen,
                            href: "/audiologist/products",
                            isActive: (pathname: string) =>
                                // pathname.startsWith("/audiologist/products"),
                                /\/audiologist\/products(?!\/my-orders)/.test(pathname),
                        },
                        // {
                        //   label: "My Orders",
                        //   icon: faLuggageCart,
                        //   href: "/audiologist/products/my-orders",
                        //   isActive: (pathname: string) =>
                        //     pathname.startsWith("/audiologist/products/my-orders"),
                        // },
                        {
                            label: "My Stock",
                            icon: faInventory,
                            href: "/audiologist/stock/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/stock/"),
                        },
                        // only show these, if we're not already showing them in the admin menu (for the office role)
                        ...(hasAdminReadAccess === false
                            ? [
                                {
                                    label: "Links",
                                    icon: faLink,
                                    href: "/audiologist/links",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/audiologist/links"),
                                },
                                {
                                    label: "FAQ",
                                    icon: faQuestion,
                                    href: "/audiologist/faqs",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/audiologist/faqs"),
                                },
                            ]
                            : []),
                        {
                            label: "Stock Statements",
                            icon: faAbacus,
                            href: "/audiologist/stock-statements/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/stock-statements"),
                        },
                    ]
                    : []),
                ...(hasAudiologReadAccess === true && audiologistKey === "xkhkk2o0yl2wap11" // xkhkk2o0yl2wap11 = Kristine Kjærsgaard ID: 101013 (DK)
                    ? [
                        {
                            label: "Audiologist Stock",
                            icon: faCarSide,
                            href: "/audiologist/audiologist-stock/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/audiologist/audiologist-stock/"),
                        },
                    ]
                    : []),
                ...(hasWarehouseWorkerReadAccess === true
                    ? [
                        // {
                        //   label: "Scan",
                        //   icon: faScanner,
                        //   href: "/warehouse-worker/scan/overview",
                        //   isActive: (pathname: string) =>
                        //     pathname.startsWith("/warehouse-worker/scan"),
                        // },
                        // {
                        //   label: "Stock",
                        //   icon: faInventory,
                        //   href: "/warehouse-worker/stock/overview",
                        //   isActive: (pathname: string) =>
                        //     pathname.startsWith("/warehouse-worker/stock/"), // Added a "/" after "stock" to not get it confused with "stock2"
                        // },
                        {
                            label: "Stock²",
                            icon: faWarehouseAlt,
                            href: "/warehouse-worker/stock2/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/warehouse-worker/stock2"),
                        },
                        {
                            label: "Stock Move",
                            icon: faTruckMoving,
                            href: "/warehouse-worker/stock-move/location-overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/warehouse-worker/stock-move"),
                        },
                        {
                            label: "Customer List",
                            icon: faUsersClass,
                            href: "/warehouse-worker/client-list/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/warehouse-worker/client-list"),
                        },
                        {
                            label: "Audiologist Stock",
                            icon: faCarSide,
                            href: "/warehouse-worker/audiologist-stock/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/warehouse-worker/audiologist-stock/"),
                        },
                        ...(hasAdminReadAccess === false
                            ? [
                                {
                                    label: "Data",
                                    icon: faDatabase,
                                    href: "/mixed/data/overview",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/mixed/data"),
                                },
                            ]
                            : []),
                        {
                            label: "Stock Tracking",
                            icon: faBoxUsd,
                            href: "/warehouse-worker/stock-tracking/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/warehouse-worker/stock-tracking"),
                        },

                        {
                            label: "Stock Statements",
                            icon: faAbacus,
                            href: "/warehouse-worker/stock-statements/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/warehouse-worker/stock-statements/"),
                        },
                        {
                            label: "Stock Statements Shared",
                            icon: faAbacus,
                            href: "/warehouse-worker/stock-statements-shared/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/warehouse-worker/stock-statements-shared"),
                        },
                        // {
                        //     label: "Stock Warnings",
                        //     icon: faAlarmExclamation,
                        //     href: "/warehouse-worker/stock-warnings/overview",
                        //     isActive: (pathname: string) =>
                        //         pathname.startsWith("/warehouse-worker/stock-warnings"),
                        // },
                        // {
                        //     label: "Return Notifications",
                        //     icon: faTruckLoading,
                        //     href: "/warehouse-worker/return-notifications/overview",
                        //     isActive: (pathname: string) =>
                        //         pathname.startsWith("/warehouse-worker/return-notifications"),
                        // },
                        ...(hasAdminReadAccess === false && hasAudiologReadAccess === false
                            ? [
                                ...(audiologistKey !== "xkhkk2o1dptd4c3k" // xkhkk2o1dptd4c3k = SINITONE Lager 1
                                    ? [{
                                        label: "Tid",
                                        icon: faUserClock,
                                        href: "/all/time/overview",
                                        isActive: (pathname: string) =>
                                            pathname.startsWith("/all/time"),
                                    }] : []),
                                ...(audiologistKey !== "xkhkk2o1dptd4c3k" // xkhkk2o1dptd4c3k = SINITONE Lager 1
                                    ? [{
                                        label: "Control",
                                        icon: faThList,
                                        href: "/mixed/customer-lists/overview",
                                        isActive: (pathname: string) =>
                                            pathname.startsWith("/mixed/customer-lists"),
                                    }] : []),
                                {
                                    label: "Coworker Status",
                                    icon: faUsers,
                                    href: "/all/coworker-status",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/all/coworker-status"),
                                },
                                ...(regionKey === "reg1onlnkmlsp7kq"
                                    ? [
                                        {
                                            label: "Calculator",
                                            icon: faCalculator,
                                            href: "/all/calculator",
                                            isActive: (pathname: string) =>
                                                pathname.startsWith("/all/calculator"),
                                        },
                                    ]
                                    : []),
                            ]
                            : []),
                        ...(hasAdminReadAccess === false && hasAudiologReadAccess === false
                            ? [
                                {
                                    label: "Documents",
                                    icon: faFolderOpen,
                                    href: "/warehouse-worker/products",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/warehouse-worker/products"),
                                },
                                {
                                    label: "Links",
                                    icon: faLink,
                                    href: "/warehouse-worker/links",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/warehouse-worker/links"),
                                },
                                {
                                    label: "FAQ",
                                    icon: faQuestion,
                                    href: "/warehouse-worker/faqs",
                                    isActive: (pathname: string) =>
                                        pathname.startsWith("/warehouse-worker/faqs"),
                                },
                            ]
                            : []),
                        // {
                        //     label: "Import Articles",
                        //     icon: faCloudDownload,
                        //     href: "/warehouse-worker/import-article-items/overview",
                        //     isActive: (pathname: string) =>
                        //         pathname.startsWith("/warehouse-worker/import-article-items"),
                        // },
                    ]
                    : []),
                ...(hasNoRole === true
                    ? [
                        {
                            label: "Overview",
                            icon: faBorderAll,
                            href: "/no-role/overview",
                            isActive: (pathname: string) =>
                                pathname.startsWith("/no-role/overview"),
                        },
                    ]
                    : []),
                ...[
                    {
                        label: "My Contacts",
                        icon: faAddressBook,
                        href: "/all/contact-list",
                        isActive: (pathname: string) =>
                            pathname.startsWith("/all/contact-list"),
                    },
                    // ...(regionKey === "reg1onlnkmlsp7kq"
                    //     ? [
                    //         {
                    //             label: "Calculator",
                    //             icon: faCalculator,
                    //             href: "/all/calculator",
                    //             isActive: (pathname: string) =>
                    //                 pathname.startsWith("/all/calculator"),
                    //         },
                    //     ]
                    //     : []),
                ]
            ],
        }),
        [hasAdminReadAccess, hasAudiologReadAccess, hasWarehouseWorkerReadAccess, hasNoRole, regionKey, audiologistKey]
    );
};
