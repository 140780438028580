import { createBoxVariantComponents } from "./setup-factories";

export const { BoxPage } = createBoxVariantComponents({
  Page: {
    display: "block",
    height: "100%",
    breakpoints: {
      medium: { margin: { top: "64px" } },
      small: { margin: { top: "64px" } },
    },
  },
});
