import { createMostlyFluidComponents } from "./setup-factories";

export const {
  MostlyFluidSpacedVertical2px,
  MostlyFluidSpacedVertical4px,
  MostlyFluidSpacedVertical8px,
  MostlyFluidSpacedVertical12px,
  MostlyFluidSpacedVertical16px,
  MostlyFluidSpacedVertical24px,
  MostlyFluidSpacedVertical32px,
  MostlyFluidSpacedVertical48px,
  MostlyFluidSpacedVertical64px,
} = createMostlyFluidComponents(
  {
    widths: ["100%"],
  },
  {
    SpacedVertical2px: { verticalPadding: "2px" },
    SpacedVertical4px: { verticalPadding: "4px" },
    SpacedVertical8px: { verticalPadding: "8px" },
    SpacedVertical12px: { verticalPadding: "12px" },
    SpacedVertical16px: { verticalPadding: "16px" },
    SpacedVertical24px: { verticalPadding: "24px" },
    SpacedVertical32px: { verticalPadding: "32px" },
    SpacedVertical48px: { verticalPadding: "48px" },
    SpacedVertical64px: { verticalPadding: "64px" },
  }
);

export const {
  MostlyFluidSpacedHorizontal2px,
  MostlyFluidSpacedHorizontal4px,
  MostlyFluidSpacedHorizontal8px,
  MostlyFluidSpacedHorizontal12px,
  MostlyFluidSpacedHorizontal16px,
  MostlyFluidSpacedHorizontal24px,
  MostlyFluidSpacedHorizontal32px,
  MostlyFluidSpacedHorizontal48px,
  MostlyFluidSpacedHorizontal64px,
} = createMostlyFluidComponents(
  {
    widths: ["auto"],
  },
  {
    SpacedHorizontal2px: { horizontalPadding: "2px" },
    SpacedHorizontal4px: { horizontalPadding: "4px" },
    SpacedHorizontal8px: { horizontalPadding: "8px" },
    SpacedHorizontal12px: { horizontalPadding: "12px" },
    SpacedHorizontal16px: { horizontalPadding: "16px" },
    SpacedHorizontal24px: { horizontalPadding: "24px" },
    SpacedHorizontal32px: { horizontalPadding: "32px" },
    SpacedHorizontal48px: { horizontalPadding: "48px" },
    SpacedHorizontal64px: { horizontalPadding: "64px" },
  }
);
