import { FC, ReactElement } from "react-router/node_modules/@types/react";
import { GLOBALS } from "./globals";
import { createBoxComponents } from "./setup-factories";
import { TextBaseLight } from "./Text";

const { BoxToast, BoxToastError, BoxToastDanger } = createBoxComponents(
  {
    padding: "14px",
    border: { radius: "6px" },
    background: { color: GLOBALS.color.green[500] },
    width: "100%",
  },
  {
    Toast: {},
    ToastError: {
      background: { color: GLOBALS.color.errorRed[500] },
    },
    ToastDanger: {
      background: { color: GLOBALS.color.dangerRed[500] },
    },
  }
);
type ToastProps =
  | {
      type: "text";
      children: string;
      color: "default" | "error" | "danger";
    }
  | {
      type: "element";
      children: ReactElement;
      color: "default" | "error" | "danger";
    };

export const Toast: FC<ToastProps> = (props) => {
  const Box =
    props.color === "default"
      ? BoxToast
      : props.color === "error"
      ? BoxToastError
      : props.color === "danger"
      ? BoxToastDanger
      : undefined;
  if (Box === undefined) {
    throw new TypeError(`Unknown color: ${props.color}`);
  }
  switch (props.type) {
    case "text":
      return (
        <Box>
          <TextBaseLight>{props.children}</TextBaseLight>
        </Box>
      );
    case "element":
      return <Box>{props.children}</Box>;
    default:
      // @ts-expect-error props.type should be never
      throw new TypeError(`Unknown type: ${props.type}`);
  }
};
