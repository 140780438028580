import { GLOBALS } from "./globals";
import { createFieldSelectComponents } from "./setup-factories";

export const { FieldSelect, FieldSelectCompact } = createFieldSelectComponents(
  {
    field: {
      label: {
        placement: "top",
        align: "start",
        padding: "8px",
        font: {
          family: "'Roboto', sans-serif",
          size: "14px",
          lineHeight: "110%",
          color: GLOBALS.color.onLight[500],
        },
      },

      error: {
        placement: "bottom",
        align: "start",
        padding: "4px",
        font: {
          family: "'Roboto', sans-serif",
          size: "12px",
          color: GLOBALS.color.errorRed[500],
        },
      },
    },

    input: {
      input: {
        angleSize: "18px",
        angleColor: GLOBALS.color.grey[600],
        border: {
          width: "1px",
          radius: {
            bottomLeft: "6px",
            bottomRight: "6px",
            topLeft: "6px",
            topRight: "6px",
          },
          style: "solid",
          color: GLOBALS.color.grey[200],
        },
        padding: {
          top: "13px",
          right: "7px",
          bottom: "13px",
          left: "14px",
        },
        font: {
          family: "'Roboto', sans-serif",
          size: "16px",
          lineHeight: "19px",
          weight: 400,
          color: GLOBALS.color.onLight[500],
        },
        placeholderColor: GLOBALS.color.grey[400],
        background: { color: "white" },
      },
      tray: {
        background: { color: "white" },
        offset: "0px",
        border: {
          width: "1px",
          style: {
            top: "none",
            right: "solid",
            bottom: "solid",
            left: "solid",
          },
          radius: {
            bottomLeft: "6px",
            bottomRight: "6px",
            topLeft: "0px",
            topRight: "0px",
          },
          color: GLOBALS.color.grey[500],
        },
        maxHeight: "calc(45px * 6)", // roughly enough for 6 items before scroll
      },
      trayItem: {
        background: { color: "white" },
        font: {},
        padding: { top: "13px", right: "14px", bottom: "13px", left: "14px" },
      },

      $when: {
        hover: {
          input: {},
          trayItem: {
            background: { color: GLOBALS.color.grey[200] },
          },
        },
        readonly: {
          input: {
            background: { color: GLOBALS.color.grey[100] },
            border: { color: GLOBALS.color.grey[200] },
          },
        },
        disabled: {
          input: {
            background: { color: GLOBALS.color.grey[100] },
            border: { color: GLOBALS.color.grey[200] },
            font: { color: GLOBALS.color.grey[400] },
          },
        },
        focus: {
          input: {
            border: {
              color: GLOBALS.color.grey[500],
              radius: {
                bottomLeft: "0px",
                bottomRight: "0px",
              },
            },
          },
        },
        invalid: {
          input: {
            border: { color: GLOBALS.color.errorRed[500] },
          },
        },
      },
    },
  },
  {
    Compact: {
      input: {
        input: {
          padding: { top: "8px", right: "9px", bottom: "8px", left: "9px" },
        },
      },
    },
  }
);
