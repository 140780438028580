import { asReactRouterLink } from "../util/asReactRouterLink";
import { GLOBALS } from "./globals";
import { createAnchorComponents } from "./setup-factories";

const { AnchorMenuInternal, AnchorMenuActiveInternal } = createAnchorComponents(
  {
    width: "100%",
    font: {
      family: GLOBALS.font.roboto.family,
      size: "16px",
      weight: 500,
      lineHeight: "100%",
      color: GLOBALS.color.purple[500],
      align: "center",
    },
    padding: { bottom: "4px", top: "0px", left: "0px", right: "0px" },
    $when: {
      active: {},
      focus: {},
      hover: {},
      link: {},
      nolink: {},
      visited: {},
    },
  },
  {
    MenuInternal: {
      $when: {
        active: {},
        focus: {},
        hover: {
          font: { color: GLOBALS.color.purple[200] },
        },
        link: {},
        nolink: {},
        visited: {},
      },
    },
    MenuActiveInternal: {
      font: { color: GLOBALS.color.grey[300] },
    },
  }
);

export const AnchorMenu = asReactRouterLink(AnchorMenuInternal);
export const AnchorMenuActive = asReactRouterLink(AnchorMenuActiveInternal);
