import { useCallback, useEffect } from "react";
import { SERVICE_HOST } from "../services";
import { useGlobalMessage } from "./GlobalMessageContext";

/**
 * Overwrite the global implementation for fetch, and look for any 403 response code for the service.
 * This is the only way we can catch these "unauthorized" calls, since (thos version of) bounded-context-reacts will
 * catch them, and simply handle them using a console.log (in development - while in production nothing happens)
 */
export const useUnauthorizedAction = () => {
  const { setGlobalMessage } = useGlobalMessage();
  const originalFetch = window.fetch;

  const fetch: typeof window.fetch = useCallback(
    (...args) => {
      const result = originalFetch(...args);

      result.then((response) => {
        // return only handle response code 403
        if (response.status !== 403) {
          return;
        }

        // the requests must be to our service
        if (response.url.startsWith(SERVICE_HOST) === false) {
          return;
        }

        setGlobalMessage({ type: "unauthorized-action" });
      });

      return result;
    },
    [originalFetch, setGlobalMessage]
  );

  useEffect(() => {
    // update the global variable, and restore it when the component is unmounted
    window.fetch = fetch;
    return () => {
      window.fetch = originalFetch;
    };
  }, [fetch, originalFetch]);
};
